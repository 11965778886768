import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { fontWeightType } from '../../constants/enums';
import { getUTCDate } from '../../helpers/time';
import { Caption1, Caption2 } from '../Fonts/Caption';
import { Subtitle } from '../Fonts/Subtitle';
import { Line } from '../Line';
import { Spacer } from '../Spacer';

export interface FarewellCardProps {
  date: string;
  canceled?: boolean;
}

const Background = styled.nav`
  background: ${({ theme }) => theme.colors.lightGray};
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.grayLink};
  border-radius: 15px;
  margin-bottom: 7px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Amount = styled.div`
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.colors.mainAguaColor};
  border-radius: 30px;
`;

const formatterPeso = Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0,
});

const FarewellCard: FC<FarewellCardProps> = ({ date, canceled }) => {
  const [amount, setAmount] = useState(0);
  const [name, setName] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setAmount(Math.round(parseInt(localStorage.getItem('price') || '0', 10)));
      setName(localStorage.getItem('name') || '');
    }
  }, []);

  return (
    <Background>
      {!canceled && (
        <>
          <Caption1 color="grayBlack" fontWeight={fontWeightType.Medium}>
            Recuerda que podrás seguir aprovechando y disfrutando los beneficios de tu actual
            suscripción y que la cancelación se hará efectiva al finalizar tu actual periodo de
            facturación.
          </Caption1>
          <Spacer size="15" />
          <Caption1 color="grayBlack" fontWeight={fontWeightType.Medium}>
            No olvides que puedes regresar cuando desees, aquí estaremos esperándote. Si adquieres
            un nuevo paquete pronto, podrás volver a acceder a tu perfil con toda tu información,
            preguntas fallidas, preguntas guardadas, retroalimentaciones de test tomados.
          </Caption1>
        </>
      )}
      {canceled && (
        <>
          <InfoContainer>
            <Caption1 color="grayBlack" fontWeight={fontWeightType.SemiBold}>
              Tu paquete actual es:
            </Caption1>
          </InfoContainer>
          <Spacer size="15" />
          <Line />
          <Spacer size="15" />
          <InfoContainer>
            <Subtitle color="grayBlack" fontWeight={fontWeightType.ExtraBold}>
              {name}
            </Subtitle>
            <Amount>
              <Caption1 color="simpleWhite" fontWeight={fontWeightType.Bold}>
                {formatterPeso.format(amount)}
              </Caption1>
            </Amount>
          </InfoContainer>
        </>
      )}
      <Spacer size="15" />
      <Line />
      <Spacer size="15" />
      {date != 'null' ? (
        <InfoContainer>
          <Caption2 color="grayBlack" fontWeight={fontWeightType.SemiBold}>
            Próxima fecha de facturación
          </Caption2>
          <Caption2 color="grayBlack" fontWeight={fontWeightType.Bold}>
            {getUTCDate(date, 1)}
          </Caption2>
        </InfoContainer>
      ) : null}
    </Background>
  );
};

export default FarewellCard;
